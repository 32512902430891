import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Magicians } from "@/containers/Magicians";
import { Heading } from "@/components/Heading";
import { HalfWrapper } from "@/components/HalfWrapper";
import { DynamicContent } from "@/components/DynamicContent";
import { PluginWaitlist } from "@/containers/PluginWaitlist";
import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";

import { Image } from "@/components/Image";
import { GoToElementLink } from "./GoToElementLink";
import { SuggestFeature } from "./SuggestFeature";

import { IMagicianSection } from "./types";

type IProps = IMagicianSection &
  IWithCustomStyle &
  JSX.IntrinsicElements["div"];

export const MagicianSection: React.FC<IProps> = ({
  title,
  subtitle,
  children,
  magicianLogoFile,
  suggestFeature = false,
  showStartsAt = true,
  cta,
  customStyle = {},
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation();

  return (
    <section
      id="MagiciansSection"
      className={css({
        height: "100%",
        minHeight: 750,
        display: "flex",
        flexDirection: "column",
        [bp.FROM_DESKTOP]: {
          flexDirection: "row",
        },
      })}
    >
      <div
        className={css({
          flex: 1,
          display: "flex",
          justifyContent: "center",
          backgroundImage: `linear-gradient(44deg, ${colors.GRADIENT_BLUE_DARK} 40%, ${colors.GRADIENT_PURPLE} 105%)`,
          [bp.FROM_DESKTOP]: {
            justifyContent: "flex-end",
          },
        })}
      >
        <HalfWrapper
          customStyle={{
            flexDirection: "column",
            ...customStyle,
          }}
        >
          <div
            className={css({
              maxWidth: 550,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "white",
              marginTop: 100,
              [bp.TO_DESKTOP]: {
                marginTop: 170,
              },
            })}
          >
            {suggestFeature && <SuggestFeature />}
            <Heading>{title}</Heading>
            <p
              className={css({
                fontSize: 18,
                maxWidth: 430,
                margin: "0 auto",
                textAlign: "center",
                [bp.ONLY_MOBILE]: {
                  marginTop: 40,
                },
              })}
            >
              {subtitle}
            </p>

            <div
              className={css({
                display: "flex",
                alignItems: "center",
                marginTop: 27,
              })}
            >
              {showStartsAt && (
                <p className={css({ fontSize: 14, fontWeight: 700 })}>
                  {t("section/magicians:startsAt")} $94
                </p>
              )}
              {cta && (
                <>
                  {showStartsAt && (
                    <span
                      className={css({
                        width: 2,
                        height: 12,
                        margin: "0 16px",
                        backgroundColor: colors.WHITE,
                        opacity: 0.2,
                      })}
                    />
                  )}
                  <GoToElementLink
                    {...cta}
                    data-testid="magicians-scroll-to-link"
                  />
                </>
              )}
            </div>
          </div>

          {magicianLogoFile ? (
            <Magicians
              fileType={magicianLogoFile}
              customStyle={{
                marginBottom: 30,
                [bp.FROM_TABLET]: {
                  marginTop: 60,
                  marginBottom: 75,
                },
              }}
            />
          ) : (
            <Image
              src="/svgs/magician-tornado.svg"
              loading="eager"
              alt="magicians with tornado"
            />
          )}
        </HalfWrapper>
      </div>

      <div
        className={css({
          flex: 1,
          display: "flex",
          background: colors.CULTURED,
          justifyContent: "center",
          padding: "40px 20px",
          [bp.FROM_DESKTOP]: {
            padding: 0,
            justifyContent: "flex-start",
          },
        })}
      >
        <HalfWrapper>
          <div
            id="upload"
            className={css({
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
              width: "100%",
            })}
          >
            {children}

            <DynamicContent>
              <div>
                <PluginWaitlist />
              </div>
            </DynamicContent>
          </div>
        </HalfWrapper>
      </div>
    </section>
  );
};
