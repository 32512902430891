import React from "react";
import { useFela } from "react-fela";

import { Input } from "@/components/Input";
import { StatusMessage } from "@/components/StatusMessage";
import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { betaSignUp } from "@/services/betaSignUpService";
import { useIsMounted } from "@/hooks/useIsMounted";
import { validateEmail } from "@/utils/validateEmail";
import { Icon, StyledIconWrapper } from "@/components/Icon";
import { IconSpinner } from "@/components/IconSpinner";

import { IBetaForm } from "./types";

export const BetaForm: React.FC<IBetaForm & IWithCustomStyle> = ({
  title,
  subtitle,
  submitLabel,
  placeholder,
  betaType,
  customStyle = {},
}) => {
  const { css } = useFela();
  const { colors, bp, fonts } = useTheme();
  const isMounted = useIsMounted();

  const [email, setEmail] = React.useState("");
  const [isEmailInvalid, setIsEmailInvalid] = React.useState(false);
  const [isSubscribed, subscribeToNewsletter] = React.useState("IDLE");
  const [isDisabled, disableSubmitButton] = React.useState(false);

  const isPluginOrFigjamForm = betaType === "PLUGIN" || betaType === "FIGJAM";

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setEmail(event.target.value);
    setIsEmailInvalid(false);
  };

  const handleFormSubmit: React.FormEventHandler = event => {
    event.preventDefault();
    subscribeToNewsletter("IDLE");
    const isEmailCorrect = validateEmail(email);
    const betaId = betaType?.toLowerCase();

    if (!isEmailCorrect) {
      setIsEmailInvalid(true);
      return;
    }

    subscribeToNewsletter("INPROGRESS");
    disableSubmitButton(true);

    betaSignUp(email, betaId || "")
      .then(() => {
        if (isMounted) {
          setEmail("");
          subscribeToNewsletter("SUCCESS");
        }
      })
      .catch(() => {
        if (isMounted) {
          subscribeToNewsletter("ERROR");
          disableSubmitButton(false);
        }
      });
  };

  return (
    <div
      className={css(
        {
          position: "absolute",
          left: "0",
          top: "30%",
          zIndex: 40,
          background: isPluginOrFigjamForm
            ? colors.GRADIENT_BLUE_DARK
            : `${colors.GRADIENT_BLUE_DARK} url(/svgs/info-icon-blue.svg) top 12px right 12px no-repeat`,
          backgroundRepeat: "no-repeat",
          borderRadius: 6,
          padding: "1rem 2rem",
          textAlign: "center",
          fontSize: 14,
          letterSpacing: 0.44,
        },
        customStyle,
      )}
    >
      {isPluginOrFigjamForm && (
        <span
          className={css({
            position: "absolute",
            top: -10,
            left: "calc(50% - 10px)",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 10px 10px 10px",
            borderColor: `transparent transparent ${colors.GRADIENT_BLUE_DARK} transparent`,
          })}
        />
      )}

      {title && (
        <h4
          className={css({
            color: colors.WHITE,
            margin: "0 0 16px",
            fontWeight: "normal",
            nested: {
              "> span": {
                color: colors.ORANGE,
              },
            },
          })}
        >
          {title}
        </h4>
      )}

      {subtitle && (
        <p
          className={css({
            color: colors.WHITE,
            fontSize: 14,
            marginBottom: 16,
          })}
        >
          {subtitle}
        </p>
      )}

      <form
        onSubmit={handleFormSubmit}
        className={css({
          display: "flex",
          marginBottom: !isPluginOrFigjamForm ? "0.5rem" : undefined,
          flexDirection: "column",
          [bp.FROM_TABLET]: {
            flexDirection: "row",
          },
        })}
      >
        <Input
          required
          type="email"
          value={email}
          onChange={handleInputChange}
          placeholder={placeholder || "Your email address"}
          customStyle={{
            border: "none",
            padding: "0.5rem 1rem",
            borderRadius: 4,
            fontSize: "14px",
            color: colors.LIGHT_GRAY,
            fontFamily: fonts.PRIMARY_FONT,
            flexGrow: 2,
            [bp.FROM_TABLET]: {
              borderRadius: "4px 0 0 4px",
              marginTop: "0",
            },
          }}
        />

        <input
          type="submit"
          value={submitLabel || "Notify Me"}
          disabled={isDisabled}
          className={css({
            borderRadius: 4,
            border: "none",
            padding: "0.5rem 1.5rem",
            fontSize: "14px",
            color: colors.WHITE,
            backgroundColor: colors.ORANGE,
            fontFamily: fonts.PRIMARY_FONT,
            marginTop: "1rem",
            appearance: "none",
            cursor: "pointer",
            opacity: isDisabled ? 0.5 : 1,
            [bp.FROM_TABLET]: {
              borderRadius: "0 4px 4px 0",
              marginTop: "0",
            },
          })}
        />
      </form>

      {isEmailInvalid && (
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: isPluginOrFigjamForm ? 10 : 15,
          })}
        >
          <StyledIconWrapper width={20} height={20}>
            <Icon iconType="ERROR-ICON" />
          </StyledIconWrapper>

          <p
            className={css({
              fontSize: 14,
              color: colors.RED,
              fontWeight: "bold",
              letterSpacing: 0.5,
              marginLeft: 10,
            })}
          >
            Please enter a valid email address
          </p>
        </div>
      )}

      {isSubscribed !== "IDLE" &&
        (isSubscribed === "INPROGRESS" ? (
          <IconSpinner
            loadingText="Submitting…"
            customStyle={{ marginTop: 16 }}
          />
        ) : (
          <StatusMessage
            isSuccess={isSubscribed === "SUCCESS"}
            successMessage="Successfully submitted"
            errorMessage="Something went wrong"
          />
        ))}
    </div>
  );
};
