import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { IConversionTarget } from "@/containers/Converter/types";

type IMagicians = {
  fileType?: IConversionTarget;
};

export const Magicians: React.FC<IMagicians & IWithCustomStyle> = ({
  fileType = "sketch",
  customStyle = {},
}) => {
  const { css } = useFela();
  const { bp } = useTheme();

  return (
    <div
      className={css(
        {
          width: "100%",
          alignSelf: "flex-end",
          position: "relative",
          nested: {
            "> img": {
              position: "absolute",
            },
          },
        },
        customStyle,
      )}
    >
      <div
        className={css({
          position: "relative",
          maxWidth: 466,
          width: "100%",
          height: 275,
          margin: "0 auto",
          [bp.ONLY_MOBILE]: {
            height: 200,
            maxWidth: 280,
            transform: "translateX(-10px)",
          },
          [bp.FROM_TABLET]: {
            transform: "translateX(-20px)",
          },
          [bp.FROM_DESKTOP]: {
            transform: "translateX(-25px)",
          },
          nested: {
            "> img": {
              position: "absolute",
            },
          },
        })}
      >
        <img
          alt="magicians back"
          src="/svgs/magicians-back.svg"
          className={css({
            bottom: 105,
            left: 35,
            zIndex: 10,
            [bp.ONLY_MOBILE]: {
              width: 245,
              left: 15,
              bottom: 65,
            },
          })}
        />

        <img
          alt="magicians front"
          src="/svgs/magicians-front.svg"
          className={css({
            left: 0,
            bottom: 0,
            zIndex: 30,
            [bp.ONLY_MOBILE]: {
              width: 280,
            },
          })}
        />

        <img
          alt={`${fileType} logo with glow`}
          src={`/svgs/${fileType}-glow.svg`}
          className={css({
            zIndex: 20,
            left: 155,
            bottom: 72,
            [bp.ONLY_MOBILE]: {
              width: 125,
              left: 90,
              bottom: 40,
            },
          })}
        />
      </div>

      <img
        alt="jar"
        src="/svgs/jar.svg"
        className={css({
          bottom: -5,
          left: -40,
          zIndex: 40,
          [bp.ONLY_MOBILE]: {
            display: "none",
          },
          [bp.FROM_DESKTOP]: {
            display: "none",
          },
          [bp.FROM_LARGE_DESKTOP]: {
            display: "block",
          },
        })}
      />

      <img
        alt="round bottle"
        src="/svgs/round-bottle.svg"
        className={css({
          right: 0,
          bottom: 30,
          [bp.ONLY_MOBILE]: {
            display: "none",
          },
          [bp.FROM_DESKTOP]: {
            display: "none",
          },
          [bp.FROM_LARGE_DESKTOP]: {
            display: "block",
          },
        })}
      />

      <img
        alt="tall bottle"
        src="/svgs/tall-bottle.svg"
        className={css({
          bottom: 160,
          left: 45,
          zIndex: 20,
          [bp.ONLY_MOBILE]: {
            display: "none",
          },
          [bp.FROM_DESKTOP]: {
            display: "none",
          },
          [bp.FROM_LARGE_DESKTOP]: {
            display: "block",
          },
        })}
      />
    </div>
  );
};
