import * as Sentry from "@sentry/node";

import { BACKEND_ENDPOINTS } from "../constants/backend";

export const betaSignUp = (email: string, format: string) => {
  const formData = {
    email,
    betaName: format,
  };

  return fetch(BACKEND_ENDPOINTS.converterBetaSignUp, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  }).catch(error => {
    Sentry.withScope(scope => {
      scope.setExtras({ email, format });
      scope.setTag("where", "Services: betaSignUp");
      Sentry.captureException(error);
    });
  });
};
