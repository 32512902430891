import React from "react";
import { useFela } from "react-fela";
import Trans from "next-translate/Trans";

import { useTheme } from "@/hooks/useTheme";
import { Card } from "@/components/Card";

type IProps = JSX.IntrinsicElements["div"];

export const SuggestFeature: React.FC<IProps> = () => {
  const { css } = useFela();
  const { colors, bp } = useTheme();

  return (
    <Card
      customStyle={{
        width: 470,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        background: "#1C0067",
        marginBottom: 32,
        [bp.ONLY_MOBILE]: {
          padding: "7px 18px",
          width: "100%",
          textAlign: "center",
        },
        [bp.FROM_TABLET]: {
          padding: "7px 18px",
        },
      }}
    >
      <span
        className={css({
          fontSize: 14,
          fontWeight: "normal",
          color: colors.STARDUST,
          margin: "0 18px 0 13px",
        })}
      >
        <Trans
          i18nKey="section/magicians:missingFeature"
          components={[
            // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
            <a
              key="0"
              href="https://feedback.magicul.io/b/feedback"
              target="_blank"
              rel="noreferrer"
              className={css({
                fontSize: 14,
                marginLeft: 10,
                cursor: "pointer",
                fontWeight: "bold",
                color: colors.RED,
                textDecoration: "underline",
              })}
            />,
          ]}
        />
      </span>
    </Card>
  );
};
