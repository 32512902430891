import React from "react";
import { useFela } from "react-fela";

import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { useTheme } from "@/hooks/useTheme";

export const HalfWrapper: React.FC<IWithCustomStyle> = ({
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();
  const { bp } = useTheme();

  return (
    <div
      className={css(
        {
          height: "100%",
          width: "100%",
          maxWidth: 690,
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          nested: {
            "@media (min-width: 1350px)": {
              maxWidth: 690,
            },
          },
          [bp.FROM_DESKTOP]: {
            justifyContent: "flex-end",
          },
        },
        customStyle,
      )}
      {...rest}
    />
  );
};
