import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { Card } from "@/components/Card";
import { useTheme } from "@/hooks/useTheme";
import { Icon } from "@/components/Icon";
import { useInquiryForm } from "@/hooks/useInquiryForm";
import { FormSourceType } from "../InquiryForm";

export const PluginWaitlist: React.FC = () => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation();
  const { toggleInquiryForm } = useInquiryForm();

  return (
    <Card
      customStyle={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        margin: "22px 0 10px",
        [bp.ONLY_MOBILE]: {
          padding: "5px 18px",
        },
        [bp.FROM_TABLET]: {
          padding: "5px 18px",
        },
      }}
    >
      <Icon iconType="PLUGIN" />
      <span
        className={css({
          fontSize: 14,
          fontWeight: "bold",
          color: colors.DARK_GREY,
          margin: "0 0 0 12px",
        })}
      >
        {t("section/magicians:preferPlugin.title")}
      </span>
      <button
        type="button"
        aria-label="Contact Sales"
        key="contact-us-button"
        onClick={() => toggleInquiryForm(FormSourceType.PLUGIN_INQUIRY)}
        className={css({
          color: colors.RED,
          fontSize: 14,
          textDecoration: "underline",
          backgroundColor: "transparent",
          border: "none",
          fontWeight: 700,
          outline: "none",
          nested: {
            ":hover": {
              cursor: "pointer",
            },
          },
        })}
      >
        Contact Sales
      </button>
    </Card>
  );
};
