import React from "react";
import { useFela } from "react-fela";
import { useDropzone } from "react-dropzone";

import { IDropzone } from "./types";
import { DropzoneContextProvider } from "./context";

type IProps = IDropzone & Omit<JSX.IntrinsicElements["div"], "onDrop">;

export const Dropzone: React.FC<IProps> = ({
  onDrop,
  accept,
  disabled = false,
  children,
  ...rest
}) => {
  const { css } = useFela();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept,
    disabled,
  });

  return (
    <DropzoneContextProvider
      isDragActive={isDragActive}
      fileRejections={fileRejections}
    >
      <div
        className={css({
          flex: 1,
          width: "100%",
          marginTop: 20,
          marginBottom: 20,
          display: "flex",
          borderRadius: 4,
          alignItems: "center",
          padding: "40px 10px",
          borderStyle: "dashed",
          justifyContent: "center",
          flexDirection: "column",
          border: "1px dashed #dadcde",
          cursor: !disabled ? "pointer" : undefined,
        })}
        {...getRootProps()}
        {...rest}
      >
        <input {...getInputProps()} data-testid="converter-file-input" />
        {children}
      </div>
    </DropzoneContextProvider>
  );
};
