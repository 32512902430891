import React from "react";
import { useFela } from "react-fela";
import { IWithCustomStyle } from "../../hooks/useTheme/types";
import { StyledIconWrapper, Icon } from "../Icon";
import { useTheme } from "../../hooks/useTheme";

interface IIconSpinner {
  loadingText: string;
}

type IProps = IIconSpinner & IWithCustomStyle & JSX.IntrinsicElements["div"];

export const IconSpinner: React.FC<IProps> = ({
  loadingText,
  customStyle = {},
}) => {
  const { css, renderer } = useFela();
  const { colors } = useTheme();

  const keyframe = () => ({
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  });

  const animation = renderer.renderKeyframe(keyframe, {});

  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...customStyle,
      })}
    >
      <StyledIconWrapper
        customStyle={{ animation: `${animation} 1.4s infinite linear` }}
      >
        <Icon iconType="SPINNER-ICON" />
      </StyledIconWrapper>
      <span
        className={css({
          marginLeft: 17,
          fontSize: 16,
          fontWeight: "bold",
          color: colors.WHITE,
        })}
      >
        {loadingText}
      </span>
    </div>
  );
};
