import React from "react";
import { useFela } from "react-fela";

import { Icon, StyledIconWrapper } from "../Icon";
import { useTheme } from "../../hooks/useTheme";
import { IStatusMessage } from "./types";
import { IWithCustomStyle } from "../../hooks/useTheme/types";

export const StatusMessage: React.FC<IStatusMessage & IWithCustomStyle> = ({
  isSuccess,
  successMessage,
  errorMessage,
  successIcon = "CHECK-ICON",
  errorIcon = "ERROR-ICON",
  customStyle = {},
  wrapperStyle = {},
}) => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 11,
        ...wrapperStyle,
      })}
    >
      <StyledIconWrapper width={16} height={16}>
        <Icon iconType={isSuccess ? successIcon : errorIcon} />
      </StyledIconWrapper>

      <span
        className={css({
          marginLeft: 11,
          fontSize: 16,
          color: colors.WHITE,
          ...customStyle,
        })}
      >
        {isSuccess ? successMessage : errorMessage}
      </span>
    </div>
  );
};
