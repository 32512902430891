import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { StyledIconWrapper, Icon } from "@/components/Icon";

export const GoToElementLink: React.FC<
  {
    title: string;
    scrollToId: string;
  } & JSX.IntrinsicElements["a"]
> = ({ title, scrollToId, ...rest }) => {
  const { css, renderer } = useFela();
  const { colors, fonts } = useTheme();

  const keyframe = () => ({
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: "translateY(-5px)" },
    "60%": { transform: "translateY(-2px)" },
  });

  const bounceAnimation = renderer.renderKeyframe(keyframe, {});

  const onClickHandler: React.MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    const element = window.document.getElementById(scrollToId);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={css({ textAlign: "center" })}>
      <a
        href={`#${scrollToId}`}
        onClick={onClickHandler}
        className={css({
          display: "inline-flex",
          color: colors.WHITE,
          textDecoration: "none",
          border: "none",
          backgroundColor: "transparent",
          nested: {
            ":hover": {
              opacity: 0.7,
              transition: "opacity 250ms",
              textDecoration: "underline",
            },
            ":hover svg": {
              animation: "none",
            },
          },
        })}
        {...rest}
      >
        <span
          className={css({
            fontFamily: fonts.PRIMARY_FONT,
            fontSize: 13,
            fontWeight: "bold",
            marginRight: 12,
          })}
        >
          {title}
        </span>
        <StyledIconWrapper
          customStyle={{
            animation: `${bounceAnimation} 2s infinite`,
            transitionTimingFunction: "cubic-bezier(.25,.46,.45,.94)",
          }}
        >
          <Icon iconType="SCROLL-DOWN-ICON" />
        </StyledIconWrapper>
      </a>
    </div>
  );
};
