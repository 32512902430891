import React from "react";
import { DropzoneState } from "react-dropzone";

type IContext = Pick<DropzoneState, "isDragActive" | "fileRejections">;

export const Context = React.createContext<IContext | null>(null);

export const useDropzoneContext = (): IContext => {
  const context = React.useContext(Context);

  if (context === null) {
    throw Error("useDropzoneContext: Please provide DropzoneContext value.");
  }

  return context;
};

export const DropzoneContextProvider: React.FC<IContext> = ({
  isDragActive,
  fileRejections,
  ...rest
}) => {
  return (
    <Context.Provider value={{ fileRejections, isDragActive }} {...rest} />
  );
};
